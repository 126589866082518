@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.CalendlyModal {
  overflow: hidden;
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    .MuiIconButton-root {
      @include icon-button;
      position: absolute;
      left: 110px;
      top: 80px;
    }
    overflow: hidden;
    min-width: 1000px;
    border-radius: 24px;
  }
}
