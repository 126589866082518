@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.demo-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .bread-crumb {
    margin-top: 24px;
    margin-left: 80px;
    width: 100%;
    .bread-crumb-btn {
      @include flexbox(flex-start, center);
      @include title-large;
      font-weight: 400;
      .bread-crumb-title{
        color: #0061A4;
      }
      border: 0px;
      background-color: transparent;
      color: #0061A4;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      .back-icon {
        margin-right: 12px;
        color: #0061A4;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    &:first-child {
      margin-top: 8px
    }

    .MuiButton-root {
      @include label-large;
      @include text-only-button;
      white-space: nowrap;
      background: #D0D9EE;
      filter: drop-shadow(0px 4px 8px rgba(0, 49, 93, 0.2));
      border-radius: 100px;
      color: $BlueSteel;
      height: 40px;
      margin-left: 20px;
    }

    p {
      @include display-small;
      white-space: nowrap;
      font-size: 32px;
      font-family: 'Manrope';
      line-height: 40px;
    }
  }

  .main {
    display: flex;
    flex-direction: row;

    .message-container {
      @include body-large;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 576px;
      width: 577px;
      background-color: #FAFAFD;
      border-radius: 24px;
      background: $Container2;
      border: 1px solid $Outline;
      box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
      padding: 40px 48px 0px;

      .message-form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 480px;
        height: 456px;
        border-bottom: 1px solid $Outline;

        .input-grid {
          display: flex;
          justify-content: space-between;
          margin: 0px;
          width: 480px;
          border-bottom: 1px solid $Outline;
          padding-bottom: 20px;

          .inner-input-grid {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0px;
            margin: 0px;

            .user-inputs {
              display: flex;
              justify-content: center;
              width: 228px;
            }
          }
          .MuiFormHelperText-root {
            @include spaced-between-flexbox(flex-start);
            align-items: center;
            gap: 4px;
            font-size: 12px;
            color: #b3261e !important;
            .MuiSvgIcon-root {
              padding: 0;
              height: 14px;
              width: 14px;
              color: #b3261e;
            }
          }

          .inner-input-grid:nth-child(1),
          .inner-input-grid:nth-child(2) {
            margin-bottom: 5px;
          }
        }

        .user-details {
          margin-bottom: 15px;
        }

        .last-paragraph {
          width: 480px;
          height: max-content;
          font-size: 16px;
          margin-top: 16px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .MuiButton-root {
        @include blue-contained-button;
        display: flex;
        justify-content: flex-end;
        margin: 24px 0px 16px auto;

        .MuiSvgIcon-root {
          margin-right: 8px;
        }
      }

      .toggle-btns {
        height: 95px;

        .MuiToggleButtonGroup-root {
          width: 480px;
          gap: 20px;

          .toggle-btn {
            width: 178px;
            height: 56px;
            border-radius: 12px;
            font-family: 'Roboto';
            text-transform: none;
            border: 1px solid #999BA5;
            font-weight: 600;
            letter-spacing: 0.075px;

            &:focus-visible {
              box-shadow: inset 0px 4px 8px rgba(18, 29, 33, 0.12);
              background: rgba(0, 28, 57, 0.1);
            }
          }

          .toggle-btn-active {
            width: 179px;
            height: 56px;
            border-radius: 12px;
            font-family: 'Roboto';
            border: 1px solid $InitioBlue;
            text-transform: none;
            font-weight: 600;
            letter-spacing: 0.075px;
            color: $InitioBlue;
            background: linear-gradient(0deg, rgba(0, 97, 164, 0.05), rgba(0, 97, 164, 0.05)), #FAFAFD;

            .MuiSvgIcon-root {
              margin-left: 11px;
            }

            &:focus-visible {
              box-shadow: inset 0px 4px 8px rgba(18, 29, 33, 0.12);
              background: rgba(0, 28, 57, 0.1);
            }
          }
        }
      }
    }

    gap: 24px;

    .MuiBox-root {
      background-image: url("../../images/mountain-image.png");
      background-repeat: no-repeat;
      width: 280px;
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      background-color: $BlueSteel;
      color: $Primary100;

      .info-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 60px 40px;
        font-size: 16px;
        height: auto;

        .contact-icon {
          width: 53px;
          height: 53px;
        }

        h2 {
          @include headline-medium;
          margin: 24px 0px 20px 0px;
          margin-bottom: 24px;
          color: #FFFFFF;
        }
        p {
          @include body-medium;
          margin-bottom: 0px;
          height: 288px;
          color: #FFFFFF;
        }
      }
    }
  }
}
