@import "../../../styles/mixins.scss";
@import "../../../styles/typography.scss";

.FormField {
  // TODO remove when needed after amvp
  .hidden-for-mvp {
    visibility: hidden;
  }
  .autocomplete-field,
  .select-field {
    margin-bottom: 25px;
  }
  .MuiFormLabel-root {
    &.Mui-error {
      color: #b3261e;
    }
  }
  .MuiFormHelperText-root {
    @include spaced-between-flexbox(flex-start);
    align-items: center;
    gap: 4px;
    font-size: 12px;
    color: #b3261e !important;
    .MuiSvgIcon-root {
      padding: 0;
      height: 14px;
      width: 14px;
      color: #b3261e;
    }
  }
  // Style for autocomplete dropdown
  .legalFormField {
    .MuiPaper-root {
      @include autocomplete-dropdown;
    }
  }
}

// Style for select dropdown
.state-dropdown {
  @include select-dropdown;
  .MuiButtonBase-root {
    @include body-medium;
  }
}
