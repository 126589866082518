@import "../styles/typography.scss";
@import "../styles/palette.scss";
@import "../styles/mixins.scss";

.BreadCrumbNav {
  @include flexbox(flex-start, center);
  margin: auto;
  margin-top: 24px;
  width: 1200px;
  color: $Primary40;
  margin-bottom: 32px;
  .MuiButtonBase-root {
    border: 0px;
    @include headline-small;
    font-size: 22px;
    color: $Primary40;
    padding: 0;
    background-color: transparent;
    text-transform: none;
    margin-right: 64px;
    &:hover {
      background-color: transparent !important;
      opacity: 0.8;
    }
    .MuiSvgIcon-root {
      margin-top: -2px;
      margin-right: 12px;
    }
  }
  h2 {
    @include headline-small;
    font-size: 22px;
    margin: 0;
  }
}
