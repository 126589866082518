@import "../../styles/palette.scss";
@import "../../styles/typography.scss";
@import "../../styles/mixins.scss";

.FinalHomepage {
  margin: 0 auto;
  width: 880px;
  margin-bottom: 48px;
  .homepage-header {
    @include flexbox(flex-start, center);
    margin-top: 63px;
    margin-bottom: 41px;
    h4 {
      @include display-small("Manrope");
      margin-bottom: 0;
      .user-name {
        color: $Primary50;
      }
    }
    .MuiButtonBase-root {
      @include tonal-contained-button;
      margin-left: 16px;
    }
  }
  &.loading-transactions-data {
    margin: 60px auto 0px;
    color: $Outline;
    display: block;
  }
  .content-wrapper {
    margin-bottom: 24px;
    h6 {
      @include title-medium;
      color: $BlueSteel;
      margin-bottom: 24px;
    }
    width: 880px;
    background: $Background1;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    border-radius: 24px;

    padding: 40px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    p {
      @include body-large;
      padding-bottom: 16px;
      .MuiButtonBase-root {
        @include button-to-link;
      }
    }
    .sandbox-report-review-wrapper {
      @include flexbox(flex-start, center);
      margin: 0 auto;
      border-radius: 12px;
      height: 250px;
    }
  }
  .info-card {
    @include flexbox(flex-start, center);
    padding: 24px;
    width: 530px;
    height: 250px;
    svg {
      width: 272px;
    }
    border-radius: 12px;
    background-color: $LightBackground;
    color: #49454f;
    border: 1px solid #b9bdc3;
    box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);
    .right-side-text {
      @include flexbox(flex-start, center, column);
      margin-left: 24px;
      width: 260px;
      p {
        @include body-large;
        padding: 0;
        margin-bottom: 8px;
        &.title {
          @include title-medium("Manrope");
        }
      }
      .sandbox-ready-text {
        p {
          display: inline;
        }
        margin-bottom: 8px;
      }
      .MuiButtonBase-root {
        @include blue-contained-button;
      }
      .info-icon-wrapper {
        @include flexbox(center, center);
        display: inline-flex;
        border-radius: 50%;
        height: 1rem;
        width: 1rem;
        margin-right: 2px;
        .MuiSvgIcon-root {
          color: $Primary50;
          height: 1rem;
          width: 1rem;
          margin: 0;
          padding: 0;
        }
        &:hover {
          border: 1px solid #dde7ed;
          outline: 1px solid #dde7ed;
        }
      }
    }
  }
  .TransactionInfoBlock {
    .overlay {
      position: absolute;
      border-radius: 12px;
      height: 250px;
      width: 246px;
      &:hover {
        cursor: pointer;
        background: rgba(0, 72, 132, 0.08);
      }
    }
    .in-progress {
      background-color: #edf1fa;
      padding: 4px 8px;
      height: 28px;
      font-family: "Roboto";
      font-style: normal;
      width: 100px;
      font-weight: 600;
      font-size: 12px;
      margin-top: 4px;
      text-align: center;
    }
    &:nth-child(2),
    &:nth-child(3) {
      margin-left: 24px;
    }
    border-radius: 12px;
    .title-background-image {
      height: 150px;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      &.report-homepage-thumb {
        background-image: url(../../images/409A_Report_Homepage_Thumbnail.svg);
      }
      &.sandbox-homepage-thumb {
        background-image: url(../../images/409A_Sandbox_Homepage_Thumbnail.svg);
      }
      &.submission-homepage-thumb {
        background-image: url(../../images/409A_SubmissionForm_Homepage_Thumbnail.svg);
      }
    }
    &.item {
      width: 246px;
      background-color: $LightBackground;
      color: #49454f;
      border: 1px solid #b9bdc3;
      filter: drop-shadow(2px 5px 4px rgba(0, 49, 93, 0.2));
      .transaction-content {
        padding-left: 16px;
        padding-right: 22px;
        padding-bottom: 21px;
        padding-top: 19px;
        min-height: 100px;

        flex-wrap: nowrap;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .left-side {
          .final-info {
            font-family: "Roboto", Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            display: block;
            margin-bottom: 0;
            padding: 0;
          }
          .sub-label {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
          }
          .title {
            @include body-large;
          }
          .text {
            @include body-small;
          }
        }
      }
    }
  }
  .previous-transactions {
    @include flexbox(flex-start, flex-start, column);
    margin-top: 48px;
    h5 {
      @include title-medium("Manrope");
      color: $BlueSteel;
      margin-bottom: 16px;
    }
    .previous-transaction-card {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 4px 8px 0px #00315d33;
      padding: 24px;
      @include flexbox(flex-start);
      margin-bottom: 16px;
      &:hover {
        cursor: pointer;
        background-color: #ecf2f8;
      }
      .val-type {
        background-color: $BlueSteel;
        padding: 10px 12px;
        @include title-medium("Manrope");
        color: $LightSurface;
        border-radius: 12px;
        margin-right: 8px;
      }
      p {
        @include title-small("Manrope");
      }
      .right-side {
        margin-left: auto;
        p {
          @include body-small;
        }
      }
    }
  }
}

.bottom-arrow-tooltip {
  &.sandbox-tooltip {
    .MuiTooltip-tooltip {
      padding: 16px 24px !important;
    }
  }
}
