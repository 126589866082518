@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";

.file-upload-container {
  @include flexbox();
  margin-top: 24px;
  .drag-and-drop-area {
    position: relative;
    border: 2px dashed $NeutralVariant70;
    border-radius: 24px;
    @include flexbox(center, center);
    gap: 12px;
    flex-direction: column;
    width: 50%;
    padding: 24px;
    .file-upload-loading-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 24px;
      z-index: 999;
      background: #fafafd;
      opacity: 0.8;
      @include flexbox(center, center);
      .loading-spinner {
        $spinnerSize: 62px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
    &.file-dragging {
      cursor: default !important;
      border: 2px dashed #0967a7;
      background-color: #f3f5fa;
    }
    .browse-or-drop {
      @include flexbox(center, center, column);
      span {
        @include body-large;
      }
      .MuiSvgIcon-root {
        color: $LightOnSurfaceVariant;
        height: 40px;
        width: 40px;
      }
      .MuiButtonBase-root {
        @include tonal-contained-button;
      }
    }
    .file-style {
      display: none;
    }
    .file-type-icons {
      display: block;
      @include body-small;
      @include spaced-between-flexbox(center);
      align-items: center;
      margin-top: 12px;
      gap: 8px;
      .icon {
        margin-top: -5px;
        display: inline-block;
        width: 20px;
        height: 30px;
        path {
          fill: #49454f;
        }
      }
      &:hover {
        cursor: default;
        color: $Primary40;
        .icon {
          path {
            fill: $Primary40;
          }
        }
      }
    }
  }
  .uploaded-files {
    width: 50%;
    .file {
      @include flexbox(flex-start, center);
      padding: 16px 20px;
      background-color: $LightPrimaryContainer;
      border-radius: 24px;
      margin-left: 15px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.25px;
      &.completed {
        background-color: #ecf9f6;
        .check-icon {
          color: $Success50;
        }
      }
      .file-name {
        @include flexbox(flex-start, center);
        gap: 8px;
        @include body-medium;
      }
      .clear-icon {
        color: #999ba5;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        padding: 2px;
        margin-left: auto;
        &:hover {
          background-color: rgba(0, 97, 164, 0.1);
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        &:active {
          background-color: rgba(0, 97, 164, 0.4);
        }
      }
    }
    .MuiLinearProgress-root {
      width: 260px;
      margin: 12px 0px 2px;
      height: 16px;
      border-radius: 16px;
      outline: 1px solid $InitioBlue;
      background-color: #fff;
      .MuiLinearProgress-bar {
        background-color: $InitioBlue;
      }
    }
  }
}

.file-type-tooltip {
  .MuiTooltip-tooltip {
    @include body-small;
    margin-bottom: 8px !important;
    background-color: #313033 !important;
    color: #ffffff;
    padding: 12px 12px !important;
    border-radius: 8px !important;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    .MuiTooltip-arrow {
      &::before {
        background-color: #313033 !important;
      }
    }
    p {
      span {
        font-weight: 600;
      }
    }
  }
}
.drag-drop-message {
  color: red;
}