@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.received-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .bread-crumb {
    margin-top: 26px;
    margin-left: 80px;
    width: 100%;

    .bread-crumb-btn {
      @include flexbox(flex-start, center);
      @include title-large;
      font-weight: 400;

      .bread-crumb-title {
        color: #0061A4;
      }

      border: 0px;
      background-color: transparent;
      color: #0061A4;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      .back-icon {
        margin-right: 12px;
        color: #0061A4;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    height: 68px;
    width: 100%;
    // margin-top: 29px;

    .MuiButton-root {
      @include label-large;
      @include text-only-button;
      white-space: nowrap;
      background: #D0D9EE;
      filter: drop-shadow(0px 4px 8px rgba(0, 49, 93, 0.2));
      border-radius: 100px;
      color: $BlueSteel;
      height: 40px;
      margin-left: 20px;
    }

    p {
      @include display-small;
      white-space: nowrap;
      font-size: 32px;
    }
  }

  .received-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 30px;

    .message-container-support {
      @include body-large;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 612px;
      width: 577px;
      background-color: #FAFAFD;
      border-radius: 24px;
      background: $Container2;
      border: 1px solid $Outline;
      box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
      padding: 40px 48px;

      h1 {
        display: flex;
        @include headline-large;
        position: relative;
        font-family: 'Manrope';
        font-size: 28px;
        margin: 0px;
        margin-bottom: 10px;
        // padding-bottom: 50px;
      }

      .success-checkmark {
        margin-bottom: 7px;
      }

      gap: 10px;

      .message-contents {
        background: #F2FAF3;
        padding: 032px 40px;

        p {
          @include body-medium;
          color: $Success50;
        }
      }

      .MuiButton-root {
        @include blue-contained-button;
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
        margin-left: auto;
      }
    }

    .message-container-demo {
      @include body-large;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 576px;
      width: 576px;
      background-color: #FAFAFD;
      border-radius: 24px;
      background: $Container2;
      border: 1px solid $Outline;
      box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
      padding: 40px 48px;

      h1 {
        display: flex;
        @include headline-large;
        position: relative;
        font-family: 'Manrope';
        font-size: 28px;
        margin: 0px;
        margin-bottom: 10px;
        // padding-bottom: 50px;
      }

      .success-checkmark {
        margin-bottom: 7px;
      }

      gap: 10px;

      .message-contents {
        background: #F2FAF3;
        padding: 032px 40px;

        p {
          @include body-medium;
          color: $Success50;
        }
      }

      .MuiButton-root {
        @include blue-contained-button;
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
        margin-left: auto;
      }
    }

    gap: 24px;

    .info-support {
      background-image: url("../../images/mountain-image.png");
      background-repeat: no-repeat;
      height: 530px;
      width: 280px;
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      background-color: $BlueSteel;
      color: $Primary100;

      .info-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 60px 40px;
        font-size: 16px;

        .contact-icon {
          width: 48px;
          height: 48px;
        }

        h2 {
          @include headline-medium;
          margin: 30px 0px 24px 0px;
          margin-bottom: 20px;
          color: #FFFFFF;
        }
        p {
          @include body-medium;
          color: #FFFFFF;
        }
      }
    }

    .info-demo {
      background-image: url("../../images/mountain-image.png");
      background-repeat: no-repeat;
      height: 576px;
      width: 280px;
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      background-color: $BlueSteel;
      color: $Primary100;

      .info-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 60px 40px;
        font-size: 16px;

        .contact-icon {
          width: 48px;
          height: 48px;
        }

        h2 {
          @include headline-medium;
          margin: 30px 0px 24px 0px;
          margin-bottom: 20px;
          color: #FFFFFF;
        }
        p {
          @include body-medium;
          color: #FFFFFF;
        }
      }
    }
  }
}