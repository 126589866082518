@import "../../../styles/mixins.scss";
@import "../../../styles/typography.scss";
@import "../../../styles/palette.scss";

.FormField {
  .MuiAutocomplete-root {
    width: auto !important;
    .dots-circle-spinner {
      font-size: 12px;
      opacity: 0.6;
    }
  }
  .MuiAutocomplete-popper {
    .MuiPaper-root {
      min-width: auto !important;
      width: auto !important;
      padding: 0 !important;
      border-radius: 12px;
    }
    .MuiAutocomplete-listbox {
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
      background-color: $Container2;
      .MuiAutocomplete-option {
        @include body-medium;
        height: 32px;
        line-height: 0;
        &[aria-selected="true"] {
          background-color: rgba(66, 149, 243, 0.08);
          box-shadow: 4px 0 0 $InitioBlue inset;
        }
        &:hover,
        &.Mui-focused {
          background-color: rgba(66, 149, 243, 0.08);
        }
        &.Mui-focusVisible {
          box-shadow: 2px 0 0 $InitioBlue inset, 0 2px 0 $InitioBlue inset, 0 -2px 0 $InitioBlue inset, -2px 0 0 $InitioBlue inset;
        }
        .primary-text {
          font-size: 12px;
          &.highlighted {
            font-weight: bold;
          }
        }
        .secondary-text {
          font-size: 10px;
          margin-left: 4px;
          margin-top: 1px;
          color: $LightOutline
        }

      }
    }
  }
}
