@import "../../styles/typography.scss";
@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";

.NavBar {
  @include flexbox(flex-start, center);
  width: 100%;
  height: 56px;
  background: transparent !important;
  padding: 0;
  border-bottom: 1px solid transparent;

  border-image: linear-gradient(
      to right,
      rgba(48, 61, 92, 0.5) 0%,
      #f5f5f5 27.6%,
      #dbe0f0 50.52%,
      rgba(219, 224, 240, 0) 100%
    )
    1;
  .navbar-nav {
    display: flex;
    align-items: center;
  }
  .no-name-sign-out {
    padding-right: 20px;
    border-radius: 2px;
    span {
      @include body-large;
    }
    .log-out-icon {
      margin-right: 11px;
    }
    &:hover {
      background-color: transparent;
      opacity: 0.8;
    }
    &:focus-visible {
      outline: 2px solid $InitioBlue;
    }
  }
  .initio-logo {
    * {
      fill: #49454f;
    }
    height: 46px;
    margin-left: 80px;
    border-radius: 2px;
    &:hover {
      background-color: transparent;
    }
    &:focus-visible {
      outline: 2px solid $InitioBlue;
    }
  }
  &.no-side-nav {
    .initio-logo {
      margin-left: 16px;
    }
  }
  .log-out-icon {
    margin-right: 11px;
  }

  .right-nav {
    margin-left: auto;
    @include spaced-between-flexbox(center);
    align-items: center;
    gap: 12px;
    .support-btn {
      color: #79747e;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      padding: 2px;
      &:hover {
        background-color: rgba(0, 97, 164, 0.1);
      }
      &:focus-visible,
      &.active {
        outline: 2px solid $InitioBlue;
      }
      &:active {
        background-color: rgba(0, 97, 164, 0.4);
      }
    }
    .user-name {
      @include body-medium;
    }
    .profile-circle-button {
      @include spaced-between-flexbox(center);
      @include body-medium;
      align-items: center;
      width: 28px;
      height: 28px;
      margin-right: 24px;
      border-radius: 50%;
      background: rgba(0, 97, 164, 0.1);
      &:hover,
      &.active {
        background: rgba(0, 72, 132, 0.16);
        outline: 2px solid rgba(0, 97, 164, 0.1);
      }
      &:focus-visible {
        outline: 2px solid #4295f3;
      }
    }
  }

  .no-name-sign-out {
    margin-right: 24px;
  }
}

.profile-menu, 
.support-dropdown {
  @include select-dropdown;
}
