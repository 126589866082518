@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.SideNavBar {
  .overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    -webkit-clip-path: polygon(64px 0, 64px 55px, 0 55px, 0 100%, 100% 100%, 100% 0);
    clip-path: polygon(64px 0, 64px 55px, 0 55px, 0 100%, 100% 100%, 100% 0);
    cursor: default !important;
  }
  .nav-toggle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1200;
    color: #49454f;
    height: 55px;
    border-radius: 0;
    background: rgba(0, 28, 57, 0.1);
    &:hover {
      background: rgba(0, 28, 57, 0.16);
    }
    &:focus-visible {
      outline: 2px solid $InitioBlue;
    }
    &:active,
    &.is-open {
      background: rgba(48, 61, 92, 0.4);
      &:hover {
        background: rgba(48, 61, 92, 0.55);
      }
    }
  }
  .nav-bar {
    position: absolute;
    z-index: 1200;
    @include flexbox(flex-start, flex-start, column);
    top: 56px;
    left: 0;
    background: $BlueSteel;
    box-shadow: 0px 8px 16px 0px rgba(0, 49, 93, 0.2);
    width: 264px;
    height: calc(100vh - 56px);
    padding: 15px 8px;
    transition: transform 0.4s ease-in-out;
    span {
      @include label-medium;
      display: block;
      color: #ffffff;
      padding: 12px;
    }
    hr {
      margin: 4px -8px 4px -8px;
      border-color: rgba(158, 202, 255, 0.16);
      width: 264px;
    }
    .nav-btn {
      width: 100%;
      color: #ffffff;
      border-radius: 30px;
      text-transform: none;
      margin: 8px 0px;
      justify-content: flex-start;
      .MuiSvgIcon-root {
        margin-right: 16px;
        margin-left: 16px;
      }
      &:hover {
        background-color: rgba(158, 202, 255, 0.3);
      }
      &:active {
        background-color: rgba(158, 202, 255, 0.4);
      }
      &.selected {
        pointer-events: none;
      }
      &.selected,
      &:focus-visible {
        background-color: rgba(158, 202, 255, 0.16);
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
    }
    .how-can-i-help {
      @include flexbox(center, center, column);
      @include title-small("Manrope");
      color: $Primary90;
      font-weight: 700;
      letter-spacing: 0.125rem;
      text-align: center;
      margin-top: 4.5rem;
      width: 15.875rem;
      margin-left: auto;
      margin-right: auto;
      img {
        @include flexbox(center, center);
        width: 10.625rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.625rem;
      }
    }
  }
  .open {
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
  }

  .closed {
    transform: translateX(-16.5rem);
    transition: transform 0.2s ease-in-out;
  }
}
