@mixin header-content-text-styles() {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #49454f;
}

.Wiz4Header {
  margin-bottom: 14px;
  h4 {
    font-family: "Manrope", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #43474e;
  }
  .icon-and-description-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    .description-wrapper {
      margin-top: 5px;
      @include header-content-text-styles();
      p {
        @include header-content-text-styles();
        margin-bottom: 0px !important;
      }
    }
    .MuiSvgIcon-root {
      color: #49454f;
    }
  }
  &.wiz4-step3 {
    width: 740px;
    margin-bottom: 36px;
  }
  &.wiz4-step4 {
    width: 720px;
    margin-bottom: 36px;
  }
}
