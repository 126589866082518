@import '../../styles/mixins.scss';

@mixin font-and-colors {
  font-family: 'Manrope', Arial, Helvetica, sans-serif;
  color: #49454F;
  font-style: normal;
}

@mixin start-btn-icon {
  [class^='MuiButton-icon'],
  [class*=' MuiButton-icon'] {
    display: inline-block;
    svg {
      margin-top: -2px;
    }
  }
}

.SplashPage {
  margin-top: 84px;
  .splash-content-wrapper {
    width: 880px;
    margin: 0 auto;
    margin-top: -50px;
    .splash-body-wrapper {
      background-color: white;
      border-radius: 24px;
      padding: 30px 10px;
      margin-bottom: 30px;
      .splash-graphic {
        margin: 0 auto;
        display: block;
        width: 460px;
      }
      .title {
        @include title-medium("Manrope");
        text-align: center;
        display: block;
        margin-bottom: 20px;
        margin-top: 20px;
      }
      p {
        @include body-large();
        text-align: center;
      }
      .button-wrapper {
        margin-top: 10px;
        button {
          @include text-only-button(underline);
          @include start-btn-icon;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}
