@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.InfoForm409A {
  &.success-container {
    margin: 0 auto;
    background: #fafafd;
    box-shadow: 0px 8px 16px rgba(0, 49, 93, 0.2);
    border-radius: 28px;
    padding: 40px 48px 30px;
    width: 880px;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      @include headline-large("Manrope");
      margin-bottom: 16px;
    }
    a {
      margin-top: 24px;
    }
    .success-msg-container {
      padding: 32px 65px;
      background: #f2faf3;
      border-radius: 4px;
      width: 784px;
      margin: 24px auto;
      p {
        @include body-medium;
        color: #008862;
      }
    }
    .payment-success-link {
      @include text-only-button(underline);
      color: $InitioBlue;
      margin-top: "24px";
    }
  }

  // !Stepper Icon Styles
  .start-btn {
    @include label-large;
    @include blue-contained-button;
    margin-top: 40px;
  }
  .bread-crumb-wrapper {
    width: 1200px;
    margin: 24px auto;
    @include flexbox(flex-start, center);
  }
  .bread-crumb {
    .bread-crumb-btn {
      @include flexbox(flex-start, center);
      margin-right: 64px;
      @include title-large;
      font-weight: 400;
      .bread-crumb-title {
        color: #0061a4;
      }
      border: 0px;
      background-color: transparent;
      color: #0061a4;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      .back-icon {
        margin-right: 8px;
        color: #0061a4;
      }
    }
  }
  .step-top-label {
    @include title-large;
    font-weight: 400;
  }
  .valuation-form-container {
    width: 880px;
    margin: 16px auto 0px;
    padding-bottom: 100px;
    .linear-progress {
      @include flexbox();
      flex-direction: column;
      .current-step {
        @include body-medium;
        text-align: right;
        width: 880px;
      }
      .MuiLinearProgress-root {
        width: 100%;
        height: 16px;
        border-radius: 16px;
        outline: 1px solid $InitioBlue;
        background: none;
        margin-bottom: 24px;
        .MuiLinearProgress-bar {
          background: linear-gradient(to right, #4295f3 0%, #3d7bc6 100%);
        }
      }
    }
    .bottom-btn-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      .back-to-review {
        @include label-large;
        @include text-only-button;
        margin-right: 5px;
        padding-left: 15px;
        padding-right: 15px;
      }
      .back-btn {
        @include label-large;
        @include outlined-button;
        margin-right: 10px;
      }
      .next-btn {
        @include label-large;
        @include blue-contained-button;
        border-radius: 100px;
      }
    }
    .valuation-form {
      width: 880px;

      position: relative;
      background-color: $LightBackground;
      box-shadow: 0px 8px 16px $WhiteContainerBoxShadow;
      border: 1px solid $Outline;
      border-radius: 24px;
      // margin: 76px auto 0px auto;
      padding: 40px 40px;
      .loading-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 24px;
        z-index: 999;
        background: #fafafd;
        opacity: 0.8;
        display: flex;
        justify-content: center;
        align-items: center;
        .custom-loading-spinner {
          $spinnerSize: 62px;
          height: $spinnerSize;
          width: $spinnerSize;
          @include spin-infinitely();
        }
      }
      .MuiAutocomplete-root {
        background: #fafafd;
        width: 380px;
        margin-top: 24px;
        margin-bottom: 24px;
      }
      h1 {
        @include headline-large("Manrope");
        color: #49454f;
        margin-bottom: 24px;
      }
      h4 {
        @include headline-large("Manrope");
        color: #49454f;
        margin-bottom: 12px;
      }
      p {
        @include body-medium;
        color: #49454f;
        margin-bottom: 24px;
      }
      .MuiFormGroup-root {
        margin-bottom: 24px;
      }
      .individual-step {
        &:hover {
          cursor: pointer;
        }
        [data-testid="CreateOutlinedIcon"] {
          position: absolute;
          top: -2px;
          margin-left: 8px;
          width: 24px;
          height: 24px;
          color: #49454f;
          border-radius: 50%;
          padding: 3px;
          &:hover {
            background-color: rgba(0, 97, 164, 0.1) !important;
          }
          &:focus-visible {
            outline: 2px solid $InitioBlue;
          }
          &:active {
            background-color: rgba(0, 97, 164, 0.4) !important;
          }
        }
      }
      .rocketShip {
        position: absolute;
        left: 5%;
      }
      .MuiStepConnector-line {
        min-height: 12px;
        border-left-width: 2px;
        border-color: #49454f;
      }
      .MuiStepLabel-root {
        padding: 0;
        .MuiStepLabel-iconContainer {
          padding: 0;
          width: 24px;
          height: 24px;
          margin-left: 2px;
          margin-right: 16px;
          border-radius: 50%;
          @include spaced-between-flexbox(center);
          align-items: center;
        }
        &.completed {
          .MuiStepLabel-iconContainer {
            background-color: $InitioBlue;
            .MuiSvgIcon-root {
              fill: #ffffff;
              padding: 3px;
            }
          }
        }
        &.is-next,
        &.has-started {
          .MuiStepLabel-iconContainer {
            @include body-small;
            line-height: 0;
            letter-spacing: 0;
            color: $InitioBlue;
            border: 2px solid $InitioBlue;
          }
        }
        &.not-started {
          .MuiStepLabel-iconContainer {
            @include body-small;
            line-height: 0;
            letter-spacing: 0;
            color: #49454f;
            border: 2px solid #49454f;
          }
        }
        .MuiStepLabel-label {
          position: relative;
          @include label-large;
          font-weight: 400;
        }
        &.is-next {
          .MuiStepLabel-label {
            font-weight: 600;
          }
        }
      }
      .MuiStepConnector-root {
        margin: 4px auto 4px 13px;
        .MuiStepConnector-line {
          min-height: 12px;
          width: 2px;
          margin-left: 0;
          background: #49454f;
          border: 1px solid #49454f;
          border-radius: 10px;
        }
      }
    }
  }
}

@media only screen and (width: 880px) {
  .InfoForm409A {
    .valuation-form-container {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
