@import "../../styles/palette.scss";
@import "../../styles/typography.scss";
@import "../../styles/mixins.scss";

.SubscriptionPage {
  .content-wrapper {
    width: 880px;
    margin: 0 auto 24px;
    background: $Container2;
    box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);
    border-radius: 24px;
    padding: 40px 48px;
    h4 {
      @include headline-large("Manrope");
      margin-bottom: 16px;
    }
    p {
      @include body-medium;
      margin-bottom: 36px;
    }
    .page-content {
      @include flexbox();
      gap: 36px;
      .left-column {
        width: 380px;
        .subscription-price {
          @include flexbox(center, center);
          min-height: 96px;
          margin-bottom: 24px;
          padding: 10px 24px;
          border-radius: 12px;
          border: 1px solid $Outline;
          background-color: $Primary95;
          p {
            @include title-large("Manrope");
            text-align: center;
            margin: 0;
            span {
              color: $InitioPurple;
            }
          }
        }
        .subscription-info {
          padding: 24px;
          border-radius: 24px;
          background-color: $LightSurface;
          h5 {
            @include title-large("Manrope");
            text-align: center;
            margin-bottom: 8px;
          }
          hr {
            border-color: $Outline;
            margin: 16px;
          }
          .text-frame {
            ul {
              padding-left: 24px;
              @include body-medium;
              li {
                list-style-type: disc;
                &::marker {
                  font-size: 12px;
                }
              }
            }
            p {
              @include body-small;
              text-align: left;
              margin: 0;
            }
          }
          p {
            @include label-large;
            text-align: center;
            margin-top: 24px;
            margin-bottom: 0;
            span {
              color: $InitioPurple;
            }
          }
        }
      }
      .right-column {
        width: 380px;
        .subscription-data {
          padding: 24px;
          border-radius: 24px;
          background-color: $LightSurface;
          h5 {
            @include title-large("Manrope");
            text-align: center;
            margin-bottom: 24px;
          }
          span {
            @include body-medium;
            margin: 0 16px;
            &.bold {
              font-weight: 600;
            }
            &.purple {
              color: $InitioPurple;
            }
          }
          .account-info-link {
            @include button-to-link;
            color: $Primary40;
            margin: 16px;
          }
          hr {
            border-color: $Outline;
            margin-bottom: 16px;
            &:last-of-type {
              margin: 0;
            }
          }
          .sub-data-btns {
            .MuiButtonBase-root {
              @include blue-contained-button;
              height: 40px;
              margin-top: 24px;
              width: 100%;
              &:last-of-type {
                @include outlined-button;
              }
              .MuiSvgIcon-root {
                height: 18px;
                width: 18px;
              }
            }
          }
        }
      }
      .cancellation-btn {
        @include outlined-button;
        height: 40px;
        width: 100%;
        margin-top: 24px;
      }
    }
  }
}

.CancellationModal,
.New409AReSubModal {
  .MuiPaper-root {
    padding: 24px 32px;
    min-width: 686px;
    border-radius: 24px;
    .MuiIconButton-root {
      @include icon-button;
      position: absolute;
      right: 24px;
      top: 24px;
    }
    h4 {
      @include flexbox(flex-start, center);
      @include headline-medium;
      .MuiSvgIcon-root {
        height: 40px;
        width: 40px;
        margin-right: 16px;
        color: $LightOutline;
      }
      margin-bottom: 16px;
    }
    p {
      @include body-large;
    }
    .bottom-btns {
      @include flexbox(flex-end, center);
      gap: 16px;
      margin-top: 24px;
      .MuiButtonBase-root {
        @include blue-contained-button;
        .MuiSvgIcon-root {
          width: 18px;
          height: 18px;
        }
        &:first-of-type {
          @include outlined-button;
        }
      }
    }
  }
}
