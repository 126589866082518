@import "../../styles/typography.scss";
@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";

.InfoForm409A {
  .Wiz1 {
    .MuiPaper-root {
      @include autocomplete-dropdown;
    }
    &.step-2,
    &.step-3 {
      p {
        margin-bottom: 24px;
        max-width: 680px;
      }
    }
  }
}
