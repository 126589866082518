@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.Wiz1 {
  &.step-1 {
    .userFormField {
      @include spaced-between-flexbox(space-around, wrap);
      align-items: center;
      gap: 12px;
      margin-top: 24px;
      margin-bottom: 24px;

      .MuiInputBase-root {
        width: 380px;
        height: 56px;
      }
    }
  }

  &.step-2 {
    .companyFormField {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(5, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 0px;

      @media only screen and (max-width: 880px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .formSection1 {
          height: 40%;
          margin: auto;
          margin-bottom:24px;
          display: block;
        }
      }

      .MuiInputBase-root {
        height: 56px;
        background-color: #fafafd;
      }

      .formSection1 {
        height: 200px;
        margin-top: 42px;
      }
      .formSection1 {
        grid-area: 1 / 1 / 5 / 5;
      }
      .formSection2 {
        grid-area: 1 / 5 / 2 / 13;
      }
      .formSection3 {
        grid-area: 2 / 5 / 3 / 13;
      }
      .formSection4 {
        grid-area: 3 / 5 / 4 / 10;
      }
      .formSection5 {
        grid-area: 3 / 10 / 4 / 13;
      }
      .formSection6 {
        grid-area: 4 / 5 / 5 / 8;
      }
      .formSection7 {
        grid-area: 4 / 8 / 5 / 10;
      }
      .formSection8 {
        grid-area: 4 / 10 / 5 / 13;
      }
      .formSection9 {
        grid-area: 5 / 1 / 6 / 5;
      }
      .formSection10 {
        grid-area: 5 / 5 / 6 / 13;
      }
    }
  }

  &.step-3 {
    .legalFormField {
      .select-field {
        //***** NOTE: Account permission select dropdown on hold for alpha MVP
        visibility: hidden; // TODO Remove this line when needed back again
      }
      @include spaced-between-flexbox(space-around, wrap);
      align-items: center;
      gap: 24px;
      margin-top: 24px;
      margin-bottom: 24px;
      .MuiFormControl-root {
        width: 380px;
        height: 56px;
      }
    }

    .legal-info-box {
      display: flex;
      align-items: center;
      padding: 24px;
      gap: 24px;
      background: linear-gradient(355deg, #d1e4ff 2.09%, #eff0f3 106.43%);
      border-radius: 24px;
      .legal-info-text {
        @include body-medium;
        margin: 0 !important;
      }
    }
  }

  .extraUsers {
    @include spaced-between-flexbox(space-around, wrap);
    align-items: center;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 24px;

    .MuiInputBase-root {
      width: 380px;
      height: 56px;
    }

    .remove-user-btn {
      @include text-only-button;
      margin: -20px 0 auto auto;
      color: #0061a4;
    }
  }

  hr {
    margin: 24px;
  }

  h4 {
    @include headline-large("Manrope");
    color: #49454f;
  }

  .title-with-helper-text {
    margin-bottom: 12px !important;
  }

  .DropdownContent {
    .strong {
      font-weight: bold;
    }
  }

  .add-field-button {
    @include outlined-button;
    display: block;
    margin-right: 0;
    margin-left: auto;
  }
}
