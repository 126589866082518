@import "../../../styles/palette.scss";
@import "../../../styles/typography.scss";
@import "../../../styles/mixins.scss";

.homepage-header {
  @include flexbox(flex-start, center);
  margin-top: 63px;
  margin-bottom: 41px;
  h4 {
    @include display-small("Manrope");
    margin-bottom: 0;
    .user-name {
      color: $Primary50;
    }
  }
  .MuiButtonBase-root {
    margin-left: 16px;
    &.new-first-transaction-btn {
      @include light-filled-button;
    }
    &.nth-transaction-btn {
      @include tonal-contained-button;
    }
  }
}
