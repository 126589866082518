@import "./palette.scss";
@import "./typography.scss";

@mixin flexbox($justifyContent: flex-start, $alignItems: flex-start, $flexDirection: row) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-direction: $flexDirection;
}

@mixin white-container($containerWidth) {
  background-color: $Container2;
  width: $containerWidth;
  box-shadow: 0px 8px 16px rgba(0, 49, 93, 0.2);
  border-radius: 28px;
  padding: 40px 48px;
  @media only screen and (max-width: 450px) {
    width: 350px;
    .MuiInputBase-root {
      width: 280px !important;
    }
  }
}

@mixin button-to-link {
  border: none;
  text-transform: none;
  text-decoration: underline;
  background: none !important;
  padding: 0;
  color: $InitioBlue;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &:active,
  &:hover,
  &:focus {
    opacity: 0.8;
  }
  &:disabled {
    color: inherit;
  }
}

@mixin spaced-between-flexbox(
  $justifyContent: space-between,
  $flexWrap: nowrap,
  $flexDirection: row
) {
  display: flex;
  flex-direction: $flexDirection;
  flex-wrap: $flexWrap;
  justify-content: $justifyContent;
}

@mixin all-browsers-transform($numDegrees) {
  -webkit-transform: rotate($numDegrees);
  -moz-transform: rotate($numDegrees);
  -ms-transform: rotate($numDegrees);
  -o-transform: rotate($numDegrees);
  transform: rotate($numDegrees);
}

@mixin default-buttons-style {
  svg {
    margin-right: 10px;
  }
  @include label-large;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  &:focus-visible {
    outline: 2px solid $InitioBlue;
  }
  &:active {
    outline: none;
  }
}

@mixin blue-contained-button {
  @include default-buttons-style;
  background-color: $Primary50;
  color: #fff;
  border: 1px solid $Primary50;
  &:hover {
    background-color: $Primary30;
    border: 1px solid $Primary30;
    box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  }
  &:focus-visible {
    outline: 2px solid $InitioBlue;
    border: 1px solid $Primary30;
    background-color: $Primary30;
  }
  &:active {
    background-color: $Primary30;
    border: 1px solid $Primary30;
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: #fff;
    background-color: $Outline;
    border: 1px solid $Outline;
  }
}

@mixin blue-contained-button-dark {
  @include default-buttons-style;
  background-color: $Primary50;
  border: 1px solid $Primary50;
  color: #fff;
  .MuiSvgIcon-root {
    color: #fff;
  }
  &:hover {
    background-color: $Primary30;
    border: 1px solid $Primary30;
    box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.20);
  }
  &:focus-visible {
    color: $DarkOnPrimary;
    outline: 2px solid $InitioBlue;
    background-color: $Primary80;
    border: 1px solid $Primary80;
  }
  &:active {
    background-color: $Primary20;
    border: 1px solid $Primary20;
  }
  &:disabled {
    opacity: 0.8;
  }
}

@mixin tonal-contained-button {
  @include default-buttons-style;
  background-color: #D0D9EE;
  color: $BlueSteel;
  box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);
  &:hover {
    background-color: #C3D4EE;
    box-shadow: 0px 4px 8px rgba(199, 203, 220, 0.85);
  }
  &:focus-visible {
    background-color: #BBCBE4;
  }
  &:active {
    background-color: #BAC2D5;
    box-shadow: inset 0px 4px 8px rgba(18, 29, 33, 0.12);
  }
  &:disabled {
    color: #303D5C;
    opacity: 0.8;
    background-color: $Outline;
  }
}

@mixin tonal-contained-button-dark {
  @include default-buttons-style;
  background-color: #D0D9EE;
  color: $BlueSteel;
  .MuiSvgIcon-root {
    color: $DeepPurple;
  }
  &:hover {
    background-color: #D1E4FF;
    box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.20);
  }
  &:focus-visible {
    background-color: #BBCBE4;
  }
  &:active {
    background-color: #bac2d5;
  }
  &:disabled {
    background-color: $Outline;
    opacity: 0.8;
  }
}

@mixin light-filled-button {
  @include default-buttons-style;
  background-color: $Container2;
  color: $Primary40;
  box-shadow: 0px 4px 8px rgba(199, 203, 220, 0.85);
  &:hover {
    background: rgba(0, 72, 132, 0.08);
    box-shadow: 0px 4px 8px rgba(199, 203, 220, 0.85);
  }
  &:focus-visible {
    background: rgba(0, 72, 132, 0.12);
  }
  &:active {
    background: rgba(0, 28, 57, 0.1);
    box-shadow: inset 0px 4px 8px rgba(18, 29, 33, 0.12);
  }
  &:disabled {
    color: rgba(48, 61, 92, 0.38);
    background-color: $Outline;
  }
}

@mixin outlined-button {
  @include default-buttons-style;
  background: none;
  border: 1px solid #315880;
  color: $Primary40;
  &:hover {
    background-color: rgba(0, 72, 132, 0.08);
  }
  &:focus-visible {
    background-color: rgba(0, 72, 132, 0.08);
    border: 1px solid $InitioBlue;
    outline: 1px solid $InitioBlue;
  }
  &:active {
    background-color: rgba(0, 28, 57, 0.1);
    box-shadow: inset 0px 4px 8px rgba(18, 29, 33, 0.12);
  }
  &:disabled {
    color: rgba(48, 61, 92, 0.38);
    border: 1px solid $Outline;
  }
}

@mixin text-only-button($textDecoration: none) {
  @include default-buttons-style;
  text-decoration: $textDecoration;
  background: none;
  border: none;
  color: $Primary40;
  &:hover {
    background-color: rgba(0, 72, 132, 0.08);
  }
  &:focus-visible {
    background-color: rgba(0, 72, 132, 0.08);
  }
  &:active {
    background-color: rgba(0, 28, 57, 0.1);
    box-shadow: inset 0px 4px 8px rgba(18, 29, 33, 0.12);
  }
  &:disabled {
    color: rgba(48, 61, 92, 0.38);
  }
}

@mixin text-only-button-dark($textDecoration: none) {
  @include default-buttons-style;
  text-decoration: $textDecoration;
  background: none;
  border: none;
  color: $Primary80;
  &:hover {
    background-color: rgba(158, 202, 255, 0.08);
  }
  &:focus-visible {
    background-color: rgba(158, 202, 255, 0.08);
  }
  &:active {
    background-color: rgba(158, 202, 255, 0.12);
  }
  &:disabled {
    color: rgba(48, 61, 92, 0.38);
  }
}

@mixin icon-button() {
  color: #999ba5;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  padding: 2px;
  &:hover {
    background-color: rgba(0, 97, 164, 0.1);
  }
  &:focus-visible {
    outline: 2px solid $InitioBlue;
  }
  &:active {
    background-color: rgba(0, 97, 164, 0.4);
  }
}

@mixin spin-infinitely($duration: 4000ms) {
  @-ms-keyframes spin {
    from { -ms-transform: rotate(0deg); }
    to { -ms-transform: rotate(360deg); }
  }
  @-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }
  -webkit-animation-name: spin;
  -webkit-animation-duration: $duration;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: $duration;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: $duration;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: $duration;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

// MUI Slider dot indicator spacing

$firstAndLastPointOffsetSpacing: 5px;

@mixin mui-slider-first-indicator-offset($finalOffset: $firstAndLastPointOffsetSpacing) {
  &[data-index="0"] { // First dot
    margin-left: $finalOffset;
  }
}

@mixin mui-slider-first-and-last-indicator-offsets(
  $final-dot-index: 20,
  $lastDotOffset: $firstAndLastPointOffsetSpacing,
  $firstDotOffset: $firstAndLastPointOffsetSpacing,
) {
  @include mui-slider-first-indicator-offset($firstDotOffset); // First dot
  &[data-index="#{$final-dot-index}"] { // Last dot
    margin-left: -$lastDotOffset;
  }
}

@mixin mui-slider-selectors-and-dot-offsets(
  $final-dot-index-higher: 20,
  $finalDotOffset: $firstAndLastPointOffsetSpacing,
  $initialDotOffset: $firstAndLastPointOffsetSpacing,
) {
  .MuiSlider-root {
    > span.MuiSlider-mark {
      @include mui-slider-first-and-last-indicator-offsets(
        $final-dot-index-higher,
        $finalDotOffset,
        $initialDotOffset
      );
    }
  }
}

@mixin always-show-scroll-bar() {
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 12px;
  }
}

:export {
  firstAndLastPointOffsetSpacing: $firstAndLastPointOffsetSpacing;
}

@mixin select-dropdown() {
  @include always-show-scroll-bar();
  border-radius: 12px;
  .MuiList-root {
    background-color: $Container2;
  }
  .MuiButtonBase-root {
    width: 100%;
    min-height: 32px;
    text-align: left;
    gap: 18px;
    &[aria-selected="true"],
    &.selected {
      background-color: rgba(66, 149, 243, 0.08);
      box-shadow: 4px 0 0 $InitioBlue inset;
      pointer-events: none;
    }
    &:hover,
    &.Mui-focusVisible {
      background-color: rgba(66, 149, 243, 0.08);
    }
    &.Mui-focusVisible {
      box-shadow: 4px 0 0 $InitioBlue inset, 0 2px 0 $InitioBlue inset,
        0 -2px 0 $InitioBlue inset, -2px 0 0 $InitioBlue inset;
    }
  }
}

@mixin autocomplete-dropdown() {
  border-radius: 12px;
  .MuiAutocomplete-listbox {
    @include always-show-scroll-bar();
    background-color: $Container1;
    .MuiListSubheader-root {
      @include label-large;
      background-color: $Container1;
      height: 32px;
      padding: 6px 16px;
    }
    .MuiAutocomplete-groupUl {
      background-color: $Container2;

      .MuiAutocomplete-option {
        @include body-medium;
        width: 100%;
        height: 32px;
        text-align: left;
        padding: 6px 16px;
        &[aria-selected="true"] {
          background-color: rgba(66, 149, 243, 0.08);
          box-shadow: 4px 0 0 $InitioBlue inset;
        }
        &:hover,
        &.Mui-focusVisible {
          background-color: rgba(66, 149, 243, 0.08);
        }
        &.Mui-focusVisible {
          box-shadow: 4px 0 0 $InitioBlue inset, 0 2px 0 $InitioBlue inset,
            0 -2px 0 $InitioBlue inset, -2px 0 0 $InitioBlue inset;
        }
      }
    }
  }
}

@mixin spinning-dots {
  @-webkit-keyframes dots-circle-rotation {
    100% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
    87.5% {
      box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em, 0em 2em 0 -0.3125em,
        -1.41421356em 1.41421356em 0 -0.25em, -2em 0em 0 -0.1875em,
        -1.41421356em -1.41421356em 0 -0.125em, 0em -2em 0 -0.0625em,
        1.41421356em -1.41421356em 0 0em;
    }
    75% {
      box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em, 0em 2em 0 -0.25em,
        -1.41421356em 1.41421356em 0 -0.1875em, -2em 0em 0 -0.125em,
        -1.41421356em -1.41421356em 0 -0.0625em, 0em -2em 0 0em,
        1.41421356em -1.41421356em 0 -0.4375em;
    }
    62.5% {
      box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em, 0em 2em 0 -0.1875em,
        -1.41421356em 1.41421356em 0 -0.125em, -2em 0em 0 -0.0625em,
        -1.41421356em -1.41421356em 0 0em, 0em -2em 0 -0.4375em,
        1.41421356em -1.41421356em 0 -0.375em;
    }
    50% {
      box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em, 0em 2em 0 -0.125em,
        -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em,
        -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em,
        1.41421356em -1.41421356em 0 -0.3125em;
    }
    37.5% {
      box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em, 0em 2em 0 -0.0625em,
        -1.41421356em 1.41421356em 0 0em, -2em 0em 0 -0.4375em,
        -1.41421356em -1.41421356em 0 -0.375em, 0em -2em 0 -0.3125em,
        1.41421356em -1.41421356em 0 -0.25em;
    }
    25% {
      box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em, 0em 2em 0 0em,
        -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em,
        -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em,
        1.41421356em -1.41421356em 0 -0.1875em;
    }
    12.5% {
      box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em, 0em 2em 0 -0.4375em,
        -1.41421356em 1.41421356em 0 -0.375em, -2em 0em 0 -0.3125em,
        -1.41421356em -1.41421356em 0 -0.25em, 0em -2em 0 -0.1875em,
        1.41421356em -1.41421356em 0 -0.125em;
    }
    0% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
  }

  @keyframes dots-circle-rotation {
    100% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
    87.5% {
      box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em, 0em 2em 0 -0.3125em,
        -1.41421356em 1.41421356em 0 -0.25em, -2em 0em 0 -0.1875em,
        -1.41421356em -1.41421356em 0 -0.125em, 0em -2em 0 -0.0625em,
        1.41421356em -1.41421356em 0 0em;
    }
    75% {
      box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em, 0em 2em 0 -0.25em,
        -1.41421356em 1.41421356em 0 -0.1875em, -2em 0em 0 -0.125em,
        -1.41421356em -1.41421356em 0 -0.0625em, 0em -2em 0 0em,
        1.41421356em -1.41421356em 0 -0.4375em;
    }
    62.5% {
      box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em, 0em 2em 0 -0.1875em,
        -1.41421356em 1.41421356em 0 -0.125em, -2em 0em 0 -0.0625em,
        -1.41421356em -1.41421356em 0 0em, 0em -2em 0 -0.4375em,
        1.41421356em -1.41421356em 0 -0.375em;
    }
    50% {
      box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em, 0em 2em 0 -0.125em,
        -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em,
        -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em,
        1.41421356em -1.41421356em 0 -0.3125em;
    }
    37.5% {
      box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em, 0em 2em 0 -0.0625em,
        -1.41421356em 1.41421356em 0 0em, -2em 0em 0 -0.4375em,
        -1.41421356em -1.41421356em 0 -0.375em, 0em -2em 0 -0.3125em,
        1.41421356em -1.41421356em 0 -0.25em;
    }
    25% {
      box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em, 0em 2em 0 0em,
        -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em,
        -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em,
        1.41421356em -1.41421356em 0 -0.1875em;
    }
    12.5% {
      box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em, 0em 2em 0 -0.4375em,
        -1.41421356em 1.41421356em 0 -0.375em, -2em 0em 0 -0.3125em,
        -1.41421356em -1.41421356em 0 -0.25em, 0em -2em 0 -0.1875em,
        1.41421356em -1.41421356em 0 -0.125em;
    }
    0% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
  }

  .dots-circle-spinner {
    display: inline-block;
    height: 1em;
    width: 1em;
    line-height: 1;
    vertical-align: middle;
    border-radius: 1em;
    transition: all 150ms linear 0s;
    transform: scale(0);
    opacity: 0;
    box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
      -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
      -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
      1.41421356em -1.41421356em 0 -0.0625em;
    transform: scale(0.25);
    opacity: 1;
    -webkit-animation: 1.5s linear 150ms normal infinite forwards running dots-circle-rotation;
    animation: 1.5s linear 150ms normal infinite forwards running dots-circle-rotation;
  }
}
