// Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

html {
  overflow: overlay;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #49454f;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;

  background: linear-gradient(161.09deg, #c5d3e8 8.61%, #e5ebf7 35.51%, #acbdda 106.03%);
  background-attachment: fixed;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
}
