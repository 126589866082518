@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.AnimatedDropdown {
  .content-dropdown {
    @include body-medium;
  }
  div .dropdown-title-container {
    @include text-only-button;
    &:hover {
      background-color: #e6ebf4;
    }
    .upward-chevron {
      margin-left: 3px;
      $upwardDegrees: -180deg;
      -webkit-transform: rotate($upwardDegrees);
      -moz-transform: rotate($upwardDegrees);
      -ms-transform: rotate($upwardDegrees);
      -o-transform: rotate($upwardDegrees);
      transform: rotate($upwardDegrees);
    }
    .downward-chevron {
      margin-left: 3px;
      $downwardDegrees: 0deg;
      -webkit-transform: rotate($downwardDegrees);
      -moz-transform: rotate($downwardDegrees);
      -ms-transform: rotate($downwardDegrees);
      -o-transform: rotate($downwardDegrees);
      transform: rotate($downwardDegrees);
    }
  }
  .added-button-wrap {
    display: flex;
    align-items: center;
  }
}
