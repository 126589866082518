@import "../../styles/palette.scss";
@import "../../styles/typography.scss";
@import "../../styles/mixins.scss";

.AccountPage {
  h2 {
    width: 880px;
    @include display-small("Manrope");
    margin: auto;
    span {
      color: $Primary50;
    }
  }
  .BreadCrumbNav {
    margin-bottom: 0;
  }
  .content-wrapper {
    width: 880px;
    margin: 24px auto;
    background: $Container2;
    box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);
    border-radius: 24px;
    padding: 40px 0;
    position: relative;
    .top-tabs-nav {
      margin-left: 40px;
      margin-bottom: 24px;
      .tab-button {
        @include label-large;
        .MuiSvgIcon-root {
          margin-right: 12px;
        }
        height: 36px;
        padding: 0;
        padding-left: 16px;
        padding-right: 16px;
        background: none;
        text-transform: none;
        border: none;
        border-radius: 0;
        border-bottom: 4px solid transparent;
        &:hover {
          border-bottom: 4px solid rgba(0, 97, 164, 0.1);
        }
        &.active-tab {
          font-weight: 600;
          border-bottom: 4px solid $InitioBlue;
          pointer-events: none;
        }
      }
      &:after {
        content: "";
        border-top: 1px solid $Outline;
        display: block;
        position: absolute;
        right: 0;
        width: 880px;
      }
    }
    .BasicInfo {
      p {
        &:first-of-type {
          @include body-medium;
          margin: 0 40px;
        }
      }
      hr {
        margin: 24px 0px;
        border-color: $Outline;
      }
      .header-row {
        @include flexbox(flex-start, center);
        gap: 10px;
        height: 64px;
        padding: 16px 36px;
        &:hover {
          cursor: pointer;
          background: rgba(0, 97, 164, 0.1);
        }
        &:active {
          background: rgba(0, 97, 164, 0.2);
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        h5 {
          @include headline-medium;
          color: $InitioPurple;
        }
        .edit-in-wizard-btn,
        .chevron-btn {
          .MuiSvgIcon-root {
            color: $InitioPurple;
          }
          @include flexbox(center, center);
          border: none;
          border-radius: 50%;
          height: 32px;
          width: 32px;
          background: none;
          &:hover {
            cursor: pointer;
            background-color: rgba(0, 97, 164, 0.1) !important;
          }
          &:focus-visible {
            outline: 2px solid $InitioBlue;
          }
          &:active {
            background-color: rgba(0, 97, 164, 0.4) !important;
          }
        }
        .edit-btn {
          margin-left: auto;
          margin-right: 6px;
          .MuiSvgIcon-root {
            color: $InitioPurple;
          }
        }
      }
      .data-tiles-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, 300px);
        justify-content: space-around;
        row-gap: 24px;
        margin: 24px 0;
        .data-tile {
          .data-name {
            @include label-medium;
            color: $NeutralVariant30;
            display: block;
            opacity: 0.8;
            margin-bottom: 4px;
          }
          .data {
            @include body-large;
            color: $NeutralVariant30;
            display: block;
            word-break: break-word;
            min-height: 24px;
          }
        }
      }
      .section-banner {
        @include flexbox();
        gap: 24px;
        padding: 0 64px;
        border: 1px solid $Outline;
        &.with-text {
          justify-content: center;
          background-color: $LightSurface;
          padding: 24px 64px;
        }
        .MuiAvatar-root {
          background-color: rgba(0, 72, 132, 0.16);
          @include body-large;
        }
        p {
          @include body-medium;
          margin-bottom: 24px;
        }
      }
      .full-blue-hr {
        border-color: $BlueSteel;
        margin: 24px 0;
      }
    }
    .PaymentHistory {
      h4 {
        @include headline-medium;
        margin: 0 40px;
      }
      hr {
        margin: 16px 0px;
        border-color: $Outline;
      }
      .payment-data-row {
        @include flexbox(flex-start);
        margin: 24px 60px;
        .data-tiles-grid {
          display: grid;
          width: 100%;
          grid-template-columns: repeat(auto-fill, 240px);
          justify-content: space-around;
          row-gap: 24px;
          .data-tile {
            .data-name {
              @include label-medium;
              color: $NeutralVariant30;
              display: block;
              opacity: 0.8;
              margin-bottom: 4px;
            }
            .data {
              @include body-large;
              color: $NeutralVariant30;
              display: block;
              word-break: break-word;
              min-height: 24px;
            }
          }
        }
        .MuiIconButton-root {
          @include icon-button;
          color: $Primary40;
          margin-top: 24px;
        }
      }
      .payment-info-hr {
        margin: auto 40px;
      }
    }
  }
}

.bottom-arrow-tooltip {
  .MuiTooltip-tooltip {
    margin-bottom: 8px !important;
    background-color: #00497d !important;
    @include body-medium;
    color: #ffffff;
    padding: 6px 8px !important;
    border-radius: 8px !important;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    .MuiTooltip-arrow {
      &::before {
        background-color: #00497d !important;
      }
    }
  }
}
