@import '../../styles/palette.scss';
@import '../../styles/typography.scss';

@mixin selected-option-styles {
  background-color: $LightPrimaryContainer;
  border-color: $LightPrimary;
  .option-text {
    color: #0075B4;
  }
  .MuiSvgIcon-root {
    color: #0063A9;
    display: block;
    margin-right: 8px;
  }
}

@mixin hover-option-styles {
  background-color: #E5ECF5;
  cursor: pointer;
}

@mixin active-option-styles {
  background-color: #E0E4EB;
  box-shadow: inset 0 0 1px #000;
  .option-text {
    color: #005F8E;
  }
}

@mixin default-option-styles {
  background: #FAFAFD;
  border: 1px solid #999BA5;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  .option-text {
    @include label-large;
  }
}

// *************************************************************************

.PanelButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  gap: 24px;
  .option {
    @include default-option-styles;
    height: 56px;
    width: 380px;
    align-items: center;
    .general-option-text {
      display: block;
    }
    &:hover {
      @include hover-option-styles;
    }
    &:active {
      @include active-option-styles;
    }
    &.is-selected {
      @include selected-option-styles;
    }
  }
  &.left-justified {
    .option {
      justify-content: space-between;
      .general-option-text {
        margin-left: 8px;
      }
    }
  }
  &.centered {
    .option {
      justify-content: center;
      .MuiSvgIcon-root {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
}

@media only screen and (max-width: 880px) {
  .PanelButtons {
    .option {
      width: 320px;
    }
  }
}

@media only screen and (max-width: 780px) {
  .PanelButtons {
    justify-content: center;
    .option {
      width: 380px;
    }
  }
}