@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.Early409AModal {
  .MuiPaper-root {
    padding: 24px 24px;
    min-width: 686px;
    border-radius: 24px;
    .MuiIconButton-root {
      @include icon-button;
      position: absolute;
      right: 24px;
      top: 24px;
    }
    h4 {
      @include flexbox(flex-start, center);
      @include headline-medium;
      width: 542px;
      .MuiSvgIcon-root {
        height: 40px;
        width: 40px;
        margin-right: 16px;
        color: $LightOutline;
      }
      margin-bottom: 16px;
    }
    p {
      @include body-large;
      margin-bottom: 24px;
      span {
        font-weight: 600;
      }
      &.softer {
        @include body-small;
        color: $LightOutline;
      }
      &.ul {
        margin: 0;
      }
    }
    ul {
      @include body-large;
    }
    .bottom-btns {
      @include flexbox(flex-end, center);
      gap: 16px;
      margin-top: 24px;
      .MuiButtonBase-root {
        @include blue-contained-button;
        .MuiSvgIcon-root {
          width: 18px;
          height: 18px;
        }
        &:first-of-type {
          @include outlined-button;
        }
      }
    }
  }
}
