@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';

.ReviewAndCheckout {
  .review-loading-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 24px;
    z-index: 999;
    background: #fafafd;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    padding-top: 200px;
    .custom-loading-spinner {
      $spinnerSize: 62px;
      height: $spinnerSize;
      width: $spinnerSize;
      @include spin-infinitely();
    }
  }
  .warning-block {
    background-color: rgba(249, 222, 220, 0.3);
    border-radius: 4px;
    padding: 32px 32px 20px;
    margin-bottom: 34px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    .warning-icon {
      display: block;
      margin-top: -5px;
      margin-right: 8px;
    }
    .warning-message {
      display: block;
      h2 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: #B3261E;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 24px;
        letter-spacing: 0.25px;
        color: #49454F;
      }
    }
  }
  .review-header-row {
    h3 {
      @include headline-large('Manrope');
      margin-bottom: 24px;
    }
  }
  .page-header-row {
    @include spaced-between-flexbox;
    h3{
      @include headline-large('Manrope');
    }
    .top-submit-info-btn {
      @include blue-contained-button;
    }
  }
  .review-block {
    padding-left: 48px;
    .ReviewInfoTile {
      margin-bottom: 20px;
    }
  }
  hr {
    border-color: $Outline;
    margin: 24px -40px;
  }
}
