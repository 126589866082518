@import '../../styles/mixins.scss';


.Wiz4 {
  .MuiFormLabel-root {
    @include flexbox(center, center);
    gap: 8px;
  }
  .MuiFormControl-root {
    margin: 24px 0px;
  }
}
