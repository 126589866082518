@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";
@import "../../styles/typography.scss";

.sharePriceCards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .share-price-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    line-height: 15px;
    width: 64px;
    height: 54px;
    border-radius: 8px;
    color: white;
    text-align: center;
    padding: 6px 10.5px;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
  }
  .responsive {
    background-color: #42A179;
    color: #FFFFFF;
    border: 1px solid transparent;
    &:hover{
      background-color: #006C4D !important;
    }
    &:focus-visible {
      outline: 2px solid #4295F3;
      border: 1px solid white;
      box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    }
    &:active {
      background-color: #005139 !important;
    }
    &.medium {
      background-color: #F4C543;
      color: #49454F;
      &:hover{
        background-color: #e9af10 !important;
      }
      &:active {
        background-color: #c79a1e !important;
      }
      &:focus-visible {
        outline: 2px solid #4295F3;
        border: 1px solid white;
        box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
      }
    }
  }
  .non-response {
    background-color: #303D5C;
    border: 1px solid transparent;
    &:hover {
      background-color: #293042 !important;
    }
    &:active {
      background-color: #101201 !important;
    }
    &:focus-visible {
      outline: 2px solid #4295F3;
      border: 1px solid white;
      box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-family: "Roboto", sans-serif;
      line-height: 15px;
      width: 64px;
      height: 54px;
      border-radius: 8px;
      color: white;
      text-align: center;
      padding: 6px 10.5px;
      box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    }
  }
  .share-price-data {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    width: 50px;
    text-transform: lowercase;
    letter-spacing: 0.075px;
  }
  .share-price-title {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 9px;
    line-height: 12px;
    font-weight: 400;
    padding-top: 3px;
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  .MuiTooltip-tooltip {
    background-color: #313033;
    border-radius: 8px;
    color: #fff;
    font-style: normal;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    padding: 16px 24px;
    font-size: 12px;
    line-height: 20px;
    width: 286px;
    min-height: 112px;
    max-height: 152px;
    margin: 0;
    word-wrap: break-word;
    font-weight: 400;
    position: relative;
    letter-spacing: 0.4px;
    .MuiTooltip-arrow{
      overflow: hidden;
      width: 1em;
      height: 0.71em;
      box-sizing: border-box;
      color:  #313033;
    }
  }
}