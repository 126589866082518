@import '../../styles/typography.scss';
@import '../../styles/palette.scss';

.ToolTip {
  background-color: $ToolTipBackgroundGrey;
  padding: 32px;
  padding-bottom: 8px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .MuiSvgIcon-root {
    display: block;
    float: left;
    width: 45px;
    height: 44px;
  }
  h5 {
    @include headline-small;
    color: #49454F;
    display: block;
    float: left;
    margin-left: 9px;
    line-height: 40px;
  }
  .body-content {
    @include body-medium;
  }
}
