@import "../../../../styles/palette.scss";
@import "../../../../styles/typography.scss";
@import "../../../../styles/mixins.scss";

.SubscriptionBanner {
  .banner {
    padding: 24px;
    border-radius: 24px;
    margin-bottom: 24px;
    &.primary-background {
      background: url("./assets/sub_banner_primary_blue_background.svg");
    }
    &.secondary-background {
      background: url("./assets/sub_banner_secondary_blue_background.svg");
    }
    .banner-text-and-icon {
      @include flexbox();
      margin-bottom: 32px;
      .MuiSvgIcon-root {
        color: white;
        height: 56px;
        width: 56px;
        margin-right: 18px;
      }
      .banner-text {
        p {
          @include body-small;
          color: white;
          &:first-of-type {
            @include headline-small;
            color: white;
            font-size: 22px;
          }
        }
      }
    }
    .MuiButtonBase-root {
      @include blue-contained-button-dark;
    }
  }
}
